import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    toggle: window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light",
  },
  reducers: {
    toggleThemeBar: (state, action) => {
      state.toggle = action.payload;
    },
  },
});

export const { toggleThemeBar } = themeSlice.actions;

export default themeSlice.reducer;
