import React, { useContext, useEffect } from "react";
import { ContextProvider } from "../App";
import Navbar from "../components/Navbar";
import Styled from "styled-components";
import Sidebar from "../components/Sidebar";
import Content from "../components/Content";
import AccountContainer from "../components/AccountContainer";
import ReadDoc from "../pages/ReadDoc";
import { loginSuccess } from "../redux/userReducer";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import SidebarMobile from "../components/SidebarMobile";

const Container = Styled.div`
overflow-y:scroll;
background-color:${(props) => props.theme.backSec};
&::-webkit-scrollbar{
  display:none;
};
`;

const Wrapper = Styled.div`
position:relative;
display:flex;
align-items:flex-start;
justify-content:center;
`;

const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const handleOauthRegister = async (credentialResponse) => {
    console.log(credentialResponse);
    // setLoading(true);
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/google-oauth-login`,

        {
          token: credentialResponse.credential,
        }
      );
      setNotification("success", "Logged-In Successfully");
      dispatch(loginSuccess(data));
    } catch (error) {
      setNotification(
        "failure",
        error.response?.data?.message || "Login failed"
      );
    }
    // setLoading(false);
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.onload = () => {
      /* Initialize Google Sign-In */

      window.google.accounts.id.initialize({
        client_id:
          "598115788575-3plobo4b6ll5h1culkokc7f3ibtc7ron.apps.googleusercontent.com",
        callback: (credentialResponse) =>
          handleOauthRegister(credentialResponse),
      });

      !user && window.google.accounts.id.prompt();
    };
    document.body.appendChild(script);
  }, []);
  return (
    <Container theme={colorTheme}>
      <AccountContainer />
      <Navbar />
      <Wrapper className="parent">
        <Sidebar />
        <SidebarMobile></SidebarMobile>
        <Content />
      </Wrapper>
    </Container>
  );
};

export default Home;
