import React, { useState, useContext } from "react";
import Text from "../components/General/Text";
import Styled from "styled-components";
import Axios from "axios";
import { ContextProvider } from "../App";
import { useNavigate } from "react-router-dom";
import { Phone, Mobile } from "../responsive";
import Button from "../components/General/Button";
import InputContainer from "../components/General/InputContainer";
import InputContainerWithEye from "../components/General/InputContainerWithEye";
import Icon from "../components/General/Icon";

const Container = Styled.div`
height:100%;
display:flex;
align-items:center;
justify-content:center;
background-color:${(props) => props.bgColor};
box-sizing:border-box;
overflow:scroll;
&::-webkit-scrollbar{
  display:none;
};
`;

const Wrapper = Styled.div`
width:30%;
height:content-fit;
padding-bottom:20px;
box-sizing:border-box;
border-radius:10px;
background-color:${(props) => props.bgColor};
${Mobile({
  height: "contentFit",
  width: "80vw",
})};
${Phone({
  height: "contentFit",
  width: "80vw",
})};
${(props) =>
  props.disabled &&
  `
    opacity: 0.3;
    pointer-events: none;
    filter: blur(1px);
  `}
`;

const HeadingContainer = Styled.div`
padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContentContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
`;

const FormContainer = Styled.div`
flex:1;
width:100%;
`;

const Form = Styled.form`
padding:5px 20px;
`;

const ButtonContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 10px;
`;

const PasswordContainer = Styled.div`
  position: relative;
`;
const IconContainer = Styled.div`
padding:5px;`;

const TextContainer = Styled.div``;

const Register = () => {
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const { backPri, backSec, fontPri } = colorTheme;
  const [loading, setLoading] = useState(false);

  const [OTPSent, setOTPSent] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [OTP, setOTP] = useState("");
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleTogglePassword2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setNotification("warning", "Please enter your email");
      return;
    }

    setLoading(true);
    try {
      await Axios.post(`${process.env.REACT_APP_API_URL}/api/v1/otp/send-otp`, {
        email: email,
      });

      setNotification("success", "OTP sent successfully");

      setOTPSent(true);
    } catch (error) {
      setNotification(
        "failure",
        error.response?.data?.message || "Registration failed"
      );
    }
    setLoading(false);
  };

  //elements
  const resetPassword = async (e) => {
    e.preventDefault();
    if (!OTP) {
      setNotification("warning", "Please enter your otp");
      return;
    }
    if (!password) {
      setNotification("warning", "Please enter your password");
      return;
    }
    if (!repeatPassword) {
      setNotification("warning", "Please confirm your password");
      return;
    }
    if (password !== repeatPassword) {
      setNotification("warning", "Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/otp/update-password`,
        {
          email: email,
          otp: OTP,
          password: password,
        }
      );

      setNotification("success", "Password updated successfully");
      navigate("/login");
    } catch (error) {
      setNotification(
        "failure",
        error.response?.data?.message || "Registration failed"
      );
    }
    setLoading(false);
  };

  return (
    <Container bgColor={backPri}>
      <Wrapper disabled={loading} bgColor={backSec}>
        <HeadingContainer>
          <IconContainer>
            <Icon />
          </IconContainer>
          <TextContainer>
            <Text
              ftColor={fontPri}
              val={"Password Reset"}
              ftSize={"30px"}
              ftWeight={"Bolder"}
            ></Text>
          </TextContainer>
        </HeadingContainer>
        <ContentContainer>
          <FormContainer>
            <Form
              autoComplete="off"
              onSubmit={OTPSent ? resetPassword : handleSubmit}
            >
              {!OTPSent && (
                <InputContainer
                  label="email"
                  placeholder="Email"
                  type="email"
                  name="email"
                  value={email}
                  setInput={setEmail}
                />
              )}
              {OTPSent && (
                <div>
                  <InputContainer
                    label="OTP"
                    placeholder="OTP"
                    type="number"
                    name="otp"
                    value={OTP}
                    setInput={setOTP}
                  />
                  <PasswordContainer>
                    <InputContainerWithEye
                      label="password"
                      placeholder="Password"
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      setInput={setPassword}
                      handleTogglePassword={handleTogglePassword}
                      passwordVisible={passwordVisible}
                    />
                  </PasswordContainer>
                  <PasswordContainer>
                    <InputContainerWithEye
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      type={passwordVisible2 ? "text" : "password"}
                      name="confirmpassword"
                      setInput={setRepeatPassword}
                      handleTogglePassword={handleTogglePassword2}
                      passwordVisible={passwordVisible2}
                    />
                  </PasswordContainer>
                </div>
              )}
              <ButtonContainer>
                <Button
                  isDisabled={loading}
                  text={OTPSent ? "Reset Password" : "Send OTP"}
                  type="submit"
                ></Button>
              </ButtonContainer>
            </Form>
          </FormContainer>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Register;
