import Styled from "styled-components";
import { Mobile } from "../responsive";
import { CopyBlock, dracula } from "react-code-blocks";
const ImageContainer = Styled.div`
`;

const Image = Styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Heading1Container = Styled.div`
padding: 0px 0px;
`;

const Heading2Container = Styled.div`
padding: 0px 0px;
`;

const Heading3Container = Styled.div`
padding: 0px 0px;
`;
const ParagraphContainer = Styled.div`
padding: 0px 10px;
`;
const ListContainer = Styled.div`
text-align:justify;
padding-left:20px;
`;

const Heading1 = Styled.span`
  font-size: 21px;
  font-weight: bold;
`;

const Heading2 = Styled.span`
font-size: 19px;
font-weight: bold;`;
const Heading3 = Styled.span`
font-size: 17px;
font-weight: bold;`;

const Paragraph = Styled.span`
font-size: 15px;
margin-right:20px;
line-height: 1;`;

const SubImageContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 25%;
${Mobile({ padding: "10px 10px" })};`;

const Li = Styled.li`
list-style-position: outside;
margin-top:0px;`;

const renderContent = ({ blogData }) => {
  return (
    <div>
      {/* <div> */}
      {blogData?.contents?.map((item, index) => {
        if (item.type === "heading1") {
          return (
            <Heading1Container>
              <Heading1 key={index}>{item.content}</Heading1>
            </Heading1Container>
          );
        } else if (item.type === "heading2") {
          return (
            <Heading2Container>
              <Heading2 key={index}>{item.content}</Heading2>
            </Heading2Container>
          );
        } else if (item.type === "heading3") {
          return (
            <Heading3Container>
              <Heading3 key={index}>{item.content}</Heading3>
            </Heading3Container>
          );
        } else if (item.type === "image") {
          return (
            <ImageContainer>
              <Image key={index} src={item.content} />
            </ImageContainer>
          );
        } else if (item.type === "subimage") {
          return (
            <SubImageContainer>
              <ImageContainer>
                <Image key={index} src={item.content} />
              </ImageContainer>
            </SubImageContainer>
          );
        } else if (item.type === "paragraph") {
          return (
            <ParagraphContainer>
              <Paragraph key={index}>{item.content}</Paragraph>
            </ParagraphContainer>
          );
        } else if (item.type === "list" || item.type === "pointers") {
          return (
            <ul style={{ listStylePosition: "outside" }}>
              <Li key={item.index}>{item.content}</Li>
            </ul>
          );
        } else if (item.type === "code") {
          return (
            <ListContainer>
              <CopyBlock
                text={item.content}
                language={item.note}
                showLineNumbers={true}
                theme={dracula}
                wrapLines
              />
            </ListContainer>
          );
        }
        return null;
      })}
      {/* </div> */}
    </div>
  );
};

export default renderContent;
