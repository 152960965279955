import React, { useContext } from "react";
import Styled from "styled-components";
import { Link } from "react-router-dom";
import { ContextProvider } from "../App";

const Container = Styled(Link)`
  display: block;
  text-decoration: none;
  border-radius: 20px;
  cursor: pointer;
  height: content-fit;
  width: 100%; /* Card will take full width of its grid cell */
  `;

const Top = Styled.div`
  
  background-color: ${(props) => props.theme.backPri};
  padding: 10px;
  border-radius: 20px;
  box-sizing: border-box;
`;

const Bottom = Styled.div`
  display: flex;
  padding: 10px;
`;

const Image = Styled.img`
  border-radius: 20px;
  width: 100%;
`;

const Left = Styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Right = Styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 3;
`;

const Icon = Styled.img`
  object-fit: cover;
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

const Title = Styled.span`
  color: ${(props) => props.theme.fontPri};
  font-size: 16px;
  font-weight: bold;
`;

const HomeCard = ({ img, title, likeCount, id }) => {
  const { colorTheme } = useContext(ContextProvider);

  return (
    <Container theme={colorTheme} to={`/doc/${id}`}>
      <Top theme={colorTheme}>
        <Image src={img} />
      </Top>
      <Bottom>
        <Left>
          <Icon src={img} />
        </Left>
        <Right>
          <Title theme={colorTheme}>{title}</Title>
        </Right>
      </Bottom>
    </Container>
  );
};

export default HomeCard;
