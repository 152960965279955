import React, { useContext, useEffect, useState } from "react";
import Styled from "styled-components";
import Axios from "axios";
import { useSelector } from "react-redux";
import { ContextProvider } from "../../App.js";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadReducer";
import { Mobile } from "../../responsive";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/userReducer";
import { loginSuccess } from "../../redux/userReducer";

import Product from "../../components/General/Product";
import NoItems from "../General/NoItems";
import Buffering from "../General/Buffering";

const Container = Styled.div`
color:${(props) => props.theme.fontPri};
`;

const Wrapper = Styled.div`
`;

const Bottom = Styled.div`
box-sizing:border-box;
display:flex;
align-items:flex-start;
justify-content:center;
padding:20px;
${Mobile({
  flexDirection: "column",
})};`;

const Info = Styled.div`
padding-right:20px;
box-sizing:border-box;
flex:3;
`;

const Summary = Styled.div`
flex:1;
box-sizing:border-box;
padding:20px;
border-radius:10px;
max-height:content-fit;
background-color:${(props) => props.theme.backPri};
${Mobile({ width: "100%" })};
`;

const SummaryTitle = Styled.h1`
text-align:center;
`;

const SummaryItem = Styled.div`
display:flex;
align-items:center;
justify-content:space-between;`;

const SummaryItemText = Styled.span``;

const SummaryItemPrice = Styled.h3`
margin-top:10px;
margin-bottom:10px;`;

const SummaryButton = Styled.button`
border-radius:5px;
padding:10px 20px;
background-color:${(props) => props.theme.fontPri};
color:${(props) => props.theme.backPri};
border:none;
font-weight:bolder;
font-size:18px;
cursor:pointer;
width:100%;
`;

const Home = () => {
  const [docs, setDocs] = useState([]);
  const user = useSelector((state) => state.user.currentUser);
  const [loading, setLoading2] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setNotification, colorTheme } = useContext(ContextProvider);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      setLoading2(true);
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/cart/getCartItems`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
        setDocs(res.data.cart);
        dispatch(loginSuccess({ ...user, cart: res?.data?.userCartData }));
      } catch (error) {
        if (error?.response?.status === 411) {
          setNotification("failure", "Sessions Exceeded! Please login again");
          dispatch(logout());
          navigate("/login");
          return;
        }
        setNotification("failure", error.response?.data?.message);
      }
      setLoading2(false);
      dispatch(setLoading(false));
    };
    fetchData();
  }, []);

  const DeleteDataFromCart = async (id) => {
    dispatch(setLoading(true));
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/removeDocFromCart`,
        { docId: id },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      setDocs(res.data.cart);
      dispatch(loginSuccess({ ...user, cart: res?.data?.userCartData }));
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification("failure", error.response?.data?.message);
    }
    dispatch(setLoading(false));
  };

  const checkoutHandler = async (amount, value = 0) => {
    if (value === 0) {
      setNotification("success", "The Platform is Free to use");
      return;
    }

    if (user.cart.length === 0) {
      return;
    }

    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/buy/checkout`,
        {
          notesToBuy: user.cart.map((item) => item.id),
        },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );

      if (data.total === 0) {
        setNotification("success", "Purchase Successful");
        const updatedUserDetail = {
          ...user,
          credits: 0,
          cart: [],
        };
        dispatch(loginSuccess(updatedUserDetail));
        navigate("/purchased");
        return;
      }
      const options = {
        key: process.env.REACT_APP_RAZORPAY_API_KEY,
        amount: data.total,
        currency: "INR",
        name: "DocShare",
        image:
          "https://docshare-profile-images.s3.ap-south-1.amazonaws.com/1730221008698",
        order_id: data.orderId,
        callback_url: `${process.env.REACT_APP_API_URL}/api/v1/buy/verifyPayment`,
        prefill: {
          name: user.name,
          email: user.email,
        },
        notes: {
          address: "Shri Jagdish Nagar, Hardoi, UP, India, 241001",
        },
        theme: {
          color: colorTheme.mainColor,
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification("failure", error.message);
    }
  };
  return (
    <Container theme={colorTheme}>
      <Wrapper>
        {loading ? (
          <Buffering />
        ) : docs.length > 0 ? (
          <Bottom>
            <Info>
              {docs.map(
                (doc) =>
                  doc.id && (
                    <Product
                      item={doc.id}
                      handleDelete={DeleteDataFromCart}
                      id={doc.id._id}
                    />
                  )
              )}
            </Info>
            {user.cart.length > 0 && (
              <Summary theme={colorTheme}>
                <SummaryTitle>Order Summary</SummaryTitle>
                <SummaryItem>
                  <SummaryItemText>Subtotal</SummaryItemText>
                  <SummaryItemPrice>₹{user.cart.length * 19}</SummaryItemPrice>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemText>Estimated Shipping</SummaryItemText>
                  <SummaryItemPrice>₹40</SummaryItemPrice>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemText>Shipping Discount</SummaryItemText>
                  <SummaryItemPrice>-₹40</SummaryItemPrice>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemText>Credits Discount</SummaryItemText>
                  <SummaryItemPrice>-₹{user.credits}</SummaryItemPrice>
                </SummaryItem>
                <SummaryItem>
                  <SummaryItemText>Total</SummaryItemText>
                  <SummaryItemPrice>
                    ₹{user.cart.length * 19 - user.credits}
                  </SummaryItemPrice>
                </SummaryItem>

                <SummaryButton
                  onClick={() =>
                    checkoutHandler(user.cart.length * 19 - user.credits)
                  }
                >
                  Checkout Now
                </SummaryButton>
              </Summary>
            )}
          </Bottom>
        ) : (
          <NoItems />
        )}
      </Wrapper>
    </Container>
  );
};

export default Home;
