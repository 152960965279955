import React, { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import Axios from "axios";

import { ContextProvider } from "../App";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/loadReducer";
import { useSelector } from "react-redux";
import { Mobile } from "../responsive";
import { loginSuccess, logout } from "../redux/userReducer";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import Buffering from "../components/General/Buffering";
import UnitItem from "../components/General/UnitItem";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";

const Container = Styled.div`
  padding-left:20px;
  padding-right:20px;
  padding-top:20px;
  color: ${(item) => item.theme.fontPri};
  ${Mobile({ paddingLeft: "7px", paddingTop: "7px", paddingRight: "7px" })};
`;

const Wrapper = Styled.div`
`;

const Top = Styled.div`
  /* margin:20px; */
  border-radius:20px;
  padding:10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items:center;
  background-color: ${(item) => item.theme.backPri};
  ${Mobile({ flexDirection: "column", margin: "0px" })};
`;

const Bottom = Styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Default: 4 columns */
  gap: 20px;
  padding-top: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr); /* Tablet: 3 columns */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* Tablet Portrait: 2 columns */
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr); /* Mobile: 1 column */
  }
  ${Mobile({ padding: "0px", paddingTop: "10px", gap: "10px" })};
`;

const Left = Styled.div`
  flex: 1;
  display:flex;
  align-items:center;
  justify-content:center;
  ${Mobile({ justifyContent: "center", width: "100%" })};
`;

const ImageContainer = Styled.div`
  /* width: 70%; */
  padding:10px;
  box-sizing:border-box;
  ${Mobile({ width: "100%", padding: "5px" })};
`;

const Image = Styled.img`
  width: 100%;
  border-radius: 20px;
`;

const Right = Styled.div`
  flex: 3;
  color: ${(item) => item.theme.fontPri};
`;

const ActionContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  padding:30px;
  ${Mobile({ padding: "5px" })};
`;

const Title = Styled.h1`
  margin:5px;
  color: ${(item) => item.theme.fontPri};
`;

const Subtitle = Styled.h2`
  margin:5px;
  color: ${(item) => item.theme.fontSec};
`;

const Description = Styled.p`
  font-size: 20px;
  color: ${(item) => item.theme.fontPri};
  text-align:justify;
`;

const ButtonContainer = Styled.div`
  width:100%;
  display:flex;
`;

const Button = Styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: ${(props) => props.theme.mainColor};
  border: none;
  cursor: pointer;
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const CartContainer = Styled.div`
  flex:1;
  padding:5px;
  display:flex;
  align-items:center;
  justify-content:center;
`;
const BuyContainer = Styled.div`
    padding:5px;
    flex:1;
`;

const HomeCard = () => {
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const loader = useSelector((state) => state.load.toggle);
  const user = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const docId = location.pathname.split("/")[2];
  const isLiked = user ? user?.liked?.some((item) => item.id === docId) : false;
  const isPurchased = user
    ? user?.purchased?.some((item) => item.id === docId)
    : false;
  const [likeLoad, setLikeLoad] = useState(false);
  const [docData, setDocData] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAddedToCart = user
    ? user?.cart?.some((item) => item.id === docId)
    : false;

  const IconStyle = {
    height: "30px",
    width: "30px",
    paddingRight: "5px",
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/docs/getDocsById/${docId}`
        );
        setDocData(res.data);
      } catch (error) {
        setNotification("failure", error.response?.data?.message);
      }
      dispatch(setLoading(false));
    };
    fetchData();
  }, []);
  const handleLike = async () => {
    if (!user) {
      setNotification("failure", "Please login to continue");
      return;
    }
    if (user && user.liked.includes(docId)) {
      setNotification("info", "Document already liked");
      return;
    }
    setLikeLoad(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/docs/likeDocById/${docId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );
      dispatch(loginSuccess({ ...user, liked: response?.data?.liked }));
      setNotification("success", response.data.message);
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification("failure", error?.response?.data?.message);
    }
    setLikeLoad(false);
  };
  const handleAddToCart = async () => {
    if (!user) {
      setNotification("failure", "Please Login to Continue");
      return;
    }
    if (user && user.cart.some((item) => item.id === docId)) {
      setNotification("success", "Document already in cart");
      return;
    }

    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/cart/addDocToCart`,
        { docId },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );

      dispatch(loginSuccess({ ...user, cart: data.cart }));
      setNotification("success", "Added to cart");
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification(
        "failure",
        error.response?.data?.message || "Document already in cart"
      );
    }
  };

  return (
    <Container theme={colorTheme}>
  
      {loader === false ? (
        <Wrapper>
          <Top theme={colorTheme}>
            <Left theme={colorTheme}>
              <ImageContainer>
                <Image src={docData.thumbnail} alt={docData.title} />
              </ImageContainer>
            </Left>
            <Right theme={colorTheme}>
              <ActionContainer>
                <Title theme={colorTheme}>{docData.docTitle}</Title>
                <Subtitle theme={colorTheme}>{docData.subtitle}</Subtitle>
                <Description theme={colorTheme}>
                  {docData.description}
                </Description>
                <ButtonContainer>
                  {!isPurchased ? (
                    <CartContainer>
                      <Button theme={colorTheme} onClick={handleAddToCart}>
                        {isAddedToCart ? (
                          <ShoppingCartRoundedIcon style={IconStyle} />
                        ) : (
                          <AddShoppingCartRoundedIcon style={IconStyle} />
                        )}
                      </Button>
                    </CartContainer>
                  ) : (
                    <BuyContainer>
                      <Button theme={colorTheme}>
                        <PaymentRoundedIcon style={IconStyle} />
                      </Button>
                    </BuyContainer>
                  )}

                  <CartContainer>
                    <Button
                      theme={colorTheme}
                      likeLoad={likeLoad}
                      onClick={handleLike}
                    >
                      {isLiked ? (
                        <ThumbUpRoundedIcon style={IconStyle} />
                      ) : (
                        <ThumbUpOutlinedIcon style={IconStyle} />
                      )}
                    </Button>
                  </CartContainer>
                </ButtonContainer>
              </ActionContainer>
            </Right>
          </Top>
          <Bottom>
            {docData?.chapters?.map((item) => (
              <UnitItem
                unitTitle={item.id.unitTitle}
                subtitle={item.id.subtitle}
                thumbnail={docData.thumbnail}
                id={item.id._id}
              />
            ))}
          </Bottom>
        </Wrapper>
      ) : (
        <Buffering />
      )}
    </Container>
  );
};

export default HomeCard;
