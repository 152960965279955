import React from "react";
import Styled from "styled-components";

const Buttons = Styled.button`
border:none;
color:white;
cursor:pointer;
background-color:#387ADF;
&:hover{
    background-color:#387Aaa;
};
padding:7px 20px;
border-radius:20px;
transition: transform 0.2s ease;
  &:active {
  transform: scale(0.95); /* Shrinks the button slightly */
  };
font-size:20px;`;

const Button = ({ text, isDisabled = false }) => {
  return <Buttons disabled={isDisabled}>{text}</Buttons>;
};

export default Button;
