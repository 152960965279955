import React, { useState, useContext } from "react";
import Text from "../../components/General/Text";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
import { Phone, Mobile } from "../../responsive";
import Button from "../../components/General/Button";
import { useNavigate, useLocation } from "react-router-dom";

const Container = Styled.div`
padding:10px 0px;
display:flex;
align-items:center;
justify-content:center;
background-color:${(props) => props.bgColor};
box-sizing:border-box;
overflow:scroll;
&::-webkit-scrollbar{
  display:none;
};
`;

const Wrapper = Styled.div`
width:50%;
height:content-fit;
padding-bottom:20px;
box-sizing:border-box;
border-radius:10px;
background-color:${(props) => props.bgColor};
${Mobile({
  height: "contentFit",
  width: "80vw",
})};
${Phone({
  height: "contentFit",
  width: "80vw",
})};

`;

const HeadingContainer = Styled.div`
padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align:center;
  flex-direction: column;
`;

const ContentContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
`;

const FormContainer = Styled.div`
flex:1;
width:100%;
`;

const Form = Styled.form`
padding:5px 10px;
`;

const ButtonContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:5px 10px;
`;

const TextContainer = Styled.div``;

const LoginPrompt = ({ message }) => {
  const { colorTheme } = useContext(ContextProvider);
  const { backPri, backSec, fontPri } = colorTheme;

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();

    const redirectTo = location.pathname;

    navigate(`/login?redirectTo=${encodeURIComponent(redirectTo)}`);
  };
  return (
    <Container bgColor={backSec}>
      <Wrapper bgColor={backPri}>
        <HeadingContainer>
          <TextContainer>
            <Text ftColor={fontPri} val={message} ftSize={"24px"}></Text>
          </TextContainer>
        </HeadingContainer>
        <ContentContainer>
          <FormContainer>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <ButtonContainer>
                <Button text={"Login"} type="submit"></Button>
              </ButtonContainer>
            </Form>
          </FormContainer>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default LoginPrompt;
