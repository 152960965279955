import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
const Container = Styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: ${(props) => props.theme.fontPri};
  background-color: ${(props) => props.theme.backSec};

  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p, ul {
    margin-bottom: 15px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  a {
    color: #007BFF;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const TermsConditions = () => {
  const { colorTheme } = useContext(ContextProvider);
  return (
    <Container theme={colorTheme}>
      <h1>Terms of Service Update</h1>
      <p>
        <strong>Effective Date:</strong> December 11, 2024
      </p>

      <p>
        Welcome to DocShare. We urge you to read these Terms of Service
        ("Terms") thoroughly before utilizing our services ("Service") provided
        by Docshare ("Company", "We", "Us", or "Our"). These Terms act as a
        binding contract between You and the Company, outlining the legal terms
        under which our Service is offered.
      </p>

      <h2>Understanding the Terminology</h2>
      <ul>
        <li>
          <strong>Affiliate:</strong> denotes any entity with significant
          control, controlled by, or under common control with a party, where
          "control" refers to owning over 50% of voting rights or equity
          interests.
        </li>
        <li>
          <strong>Country:</strong> India, the principal location of our
          Company.
        </li>
        <li>
          <strong>Device:</strong> includes any technology capable of accessing
          the Service like computers, smartphones, or tablets.
        </li>
        <li>
          <strong>Service:</strong> is the Website operated by the Company.
        </li>
        <li>
          <strong>Third-party Social Media Service:</strong> covers any platform
          that allows login or account creation to use our Service.
        </li>
        <li>
          <strong>Website:</strong> refers to Docshare, accessible via{" "}
          <a href="https://docshare.in">https://docshare.in</a>.
        </li>
        <li>
          <strong>You:</strong> represents the individual or legal entity using
          our Service.
        </li>
      </ul>

      <h2>Agreement to Terms</h2>
      <p>
        By accessing or using our Service, you confirm that you accept these
        Terms and agree to abide by them. If you disagree with any part of the
        Terms, you must not use our Service. Users under the age of 18 are not
        permitted to use our Service.
      </p>

      <h2>External Links Disclaimer</h2>
      <p>
        Our Service may contain links to external websites not operated by us.
        We have no control over and assume no responsibility for the content or
        practices of any third-party sites or services. We advise you to review
        the Terms and Privacy Policy of any third-party sites you visit.
      </p>

      <h2>Service Termination</h2>
      <p>
        We reserve the right to terminate or suspend your access to our Service
        immediately, without prior notice, for any reason, including breach of
        these Terms. Upon termination, your right to use the Service will cease
        immediately.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        In no event shall the Company or its suppliers be liable for any
        indirect, incidental, special, or consequential damages arising out of
        or related to your use of the Service. The total liability of the
        Company, regardless of the form of action, shall not exceed the greater
        of 100 USD or the amount you have paid to us for the Service.
      </p>

      <h2>Disclaimer of Warranties</h2>
      <p>
        The Service is provided "AS IS" and "AS AVAILABLE" without any warranty
        of any kind. The Company disclaims all warranties, express or implied,
        including but not limited to implied warranties of merchantability,
        fitness for a particular purpose, and non-infringement.
      </p>

      <h2>Governing Law and Dispute Resolution</h2>
      <p>
        These Terms shall be governed by the laws of the Country, without regard
        to its conflict of law provisions. Any disputes arising from these Terms
        will be resolved through informal negotiation or through an appropriate
        court in the Country.
      </p>

      <h2>For EU Users</h2>
      <p>
        EU consumers are entitled to any mandatory provisions of the law of
        their resident country.
      </p>

      <h2>US Legal Compliance</h2>
      <p>
        You represent that you are not located in a country under US embargo or
        designated as a terrorist-supporting country and that you are not listed
        on any US prohibited parties list.
      </p>

      <h2>Severability and Waiver</h2>
      <p>
        If any provision of these Terms is deemed unenforceable, the remaining
        provisions will remain in full effect. Failure to enforce any right or
        provision will not be considered a waiver of those rights.
      </p>

      <h2>Amendment of Terms</h2>
      <p>
        We reserve the right to modify or replace these Terms at any time. If a
        revision is material, we will provide at least 30 days' notice before
        new terms take effect. By continuing to use our Service after revisions
        become effective, you agree to be bound by the updated Terms.
      </p>

      <h2>Contact Information</h2>
      <p>
        For any queries about these Terms, please contact us via our email:{" "}
        <a href="mailto:docsharehelp@gmail.com">docsharehelp@gmail.com</a>.
      </p>
    </Container>
  );
};

export default TermsConditions;
