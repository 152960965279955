import React, { useContext } from "react";
import Styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Mobile } from "../responsive";
import { ContextProvider } from "../App";
import { toggleSidebar } from "../redux/sideReducer";
import { useDispatch } from "react-redux";
import FeedbackRoundedIcon from "@mui/icons-material/FeedbackRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const ParentContainer = Styled.div`
display:none;
width:100vw;
height:100vh;
top:0px;
left:0px;
bottom:0px;
visibility:${(props) => (props.toggle ? "visible" : "hidden")};
position:fixed;
z-index:6;
background-color: rgba(0, 0, 0, 0.4);
backdrop-filter: blur(1px);
${Mobile({ display: "flex" })};
opacity:1;`;

const Container = Styled.div`
width:200px;
top:0px;
bottom:0px;
z-index:5;
height:100vh;
position:relative;
background-color:${(props) => props.theme.backPri};
color:${(props) => props.theme.fontPri};
margin-left:${(props) => (props.toggle !== true ? "-300px" : "0px")};;
transition:all 0.3s ease-in-out;

`;

const Wrapper = Styled.div`
display:flex;
align-items:flex-start;
justify-content:center;
padding:10px;
`;

const Table = Styled.table`
border:none;
width:90%;`;

const Tr = Styled.tr`
margin-top:5px;
padding-top:10px; 
padding-bottom:10px; 
display:flex;
align-items:center;
border-radius:20px;
background-color:${(props) =>
  props.location === props.name && props.theme.backSec};
justify-content:center;
&:hover{
  cursor:pointer;
  background-color:${(props) => props.theme.backSec};;

};`;

const TrTop = Styled.tr`

padding-top:10px; 
padding-bottom:10px; 
display:flex;
align-items:center;
border-radius:20px;
justify-content:center;
`;

const TdIcon1 = Styled.td`
padding-left:5px;
display:flex;
align-items:center;
justify-content:center;
flex:1;`;

const TdText = Styled.td`
padding-left:10px;
flex:3;`;

const Heading = Styled.span`
font-size:18px;
`;

const TitleContainer = Styled.div`
`;

const Title = Styled.span`
  font-weight: 600;
  font-size: 26px;
  margin: 0px auto;
  
  cursor: pointer;
  color: ${(props) => props.theme.fontPri};
  font-family: "Nunito", sans-serif;
  ${Mobile({ fontSize: "24px" })};
`;

const Span = Styled.span`
  font-size: 26px;
  ${Mobile({ fontSize: "24px" })};
  color: #387ADF;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
`;

const FeedbackButton = Styled.div`
  position: absolute;
  bottom: 20px;
  left: 10px;
  margin-top: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border:${(props) => `3px solid ${props.theme.mainColor}`};
  transition:all 0.4s ease;
  border-radius:20px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.mainColor};
    color: ${(props) => props.theme.backPri};
  }
`;

const Sidebar = () => {
  const sidebar = useSelector((state) => state.sidebar.toggle);
  const { colorTheme } = useContext(ContextProvider);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const IconStyle = {
    height: "30px",
    width: "30px",
  };
  let Location = useLocation();
  Location = Location.pathname.split("/")[1];

  const handleClick = (path) => {
    navigate("/" + path);
    dispatch(toggleSidebar());
  };

  const handleClickParent = (e) => {
    if (e.target.classList.contains("parent")) {
      dispatch(toggleSidebar());
    }
  };

  const handleFeedbackClick = () => {
    const whatsappLink =
      "https://wa.me/918115387942?text=Hello,%20I%20need%20the%20notes%20of%20this%20Subject(s):";
    window.open(whatsappLink, "_blank");
  };
  return (
    <ParentContainer
      toggle={sidebar}
      className="parent"
      onClick={(e) => handleClickParent(e)}
    >
      <Container theme={colorTheme} className="child" toggle={sidebar}>
        <Wrapper>
          <Table>
            <TrTop>
              <TdIcon1>
                <MenuRoundedIcon
                  style={IconStyle}
                  onClick={() => dispatch(toggleSidebar())}
                />
              </TdIcon1>
              <TdText>
                <TitleContainer onClick={() => navigate("/home")}>
                  <Title theme={colorTheme}>
                    Doc
                    <Span>Share</Span>
                  </Title>
                </TitleContainer>
              </TdText>
            </TrTop>
            <Tr
              location={Location}
              theme={colorTheme}
              name={"home"}
              onClick={() => {
                handleClick("home");
              }}
            >
              <TdIcon1>
                <HomeRoundedIcon style={IconStyle} />
              </TdIcon1>
              <TdText>
                <Heading>Home</Heading>
              </TdText>
            </Tr>
            <Tr
              location={Location}
              theme={colorTheme}
              name={"liked"}
              onClick={() => {
                handleClick("liked");
              }}
            >
              <TdIcon1>
                <ThumbUpRoundedIcon style={IconStyle} />
              </TdIcon1>
              <TdText>
                <Heading>Liked</Heading>
              </TdText>
            </Tr>
            <Tr
              location={Location}
              name={"mynotes"}
              theme={colorTheme}
              onClick={() => {
                handleClick("mynotes");
              }}
            >
              <TdIcon1>
                <DescriptionRoundedIcon style={IconStyle} />
              </TdIcon1>
              <TdText>
                <Heading>Notes</Heading>
              </TdText>
            </Tr>
            <Tr
              location={Location}
              name={"cart"}
              theme={colorTheme}
              onClick={() => {
                handleClick("cart");
              }}
            >
              <TdIcon1>
                <ShoppingCartRoundedIcon style={IconStyle} />
              </TdIcon1>
              <TdText>
                <Heading>Cart</Heading>
              </TdText>
            </Tr>
            <Tr
              location={Location}
              name={"orders"}
              theme={colorTheme}
              onClick={() => {
                handleClick("orders");
              }}
            >
              <TdIcon1>
                <ShoppingBagRoundedIcon style={IconStyle} />
              </TdIcon1>
              <TdText>
                <Heading>Orders</Heading>
              </TdText>
            </Tr>

            <Tr
              location={Location}
              name={"purchased"}
              theme={colorTheme}
              onClick={() => {
                handleClick("purchased");
              }}
            >
              <TdIcon1>
                <PaymentRoundedIcon style={IconStyle} />
              </TdIcon1>
              <TdText>
                <Heading>Purchased</Heading>
              </TdText>
            </Tr>
          </Table>
        </Wrapper>
        <FeedbackButton theme={colorTheme} onClick={handleFeedbackClick}>
          <TdIcon1>
            <FeedbackRoundedIcon style={IconStyle} />
          </TdIcon1>
          <TdText>
            <Heading>Request Notes</Heading>
          </TdText>
        </FeedbackButton>
      </Container>
    </ParentContainer>
  );
};

export default Sidebar;
