import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
const Container = Styled.div`

height:calc(100vh - 60px);
min-width:100%;
display:flex;
align-items:center;
justify-content:center;
`;

const Heading = Styled.h1`
color:${(props) => props.theme.fontSec};
margin:0px;
font-size:40px;`;

const NoItems = () => {
  const { colorTheme } = useContext(ContextProvider);
  return (
    <Container>
      <Heading theme={colorTheme}>No Items Here</Heading>
    </Container>
  );
};

export default NoItems;
