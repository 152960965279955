import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import { Phone, Tablet } from "../responsive";
import { useNavigate } from "react-router-dom";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";

const Container = Styled.div`
  height: content-fit;
  border-radius: 10px;
  /* margin: 10px; */
  flex: 1;
  min-width: 20%;
  cursor: pointer;
  ${Phone({ minWidth: "95%" })};
  ${Tablet({ minWidth: "45%" })};
`;

const Top = Styled.div`
  background-color: ${(props) => props.theme.backPri};
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Left = Styled.div`
  flex: 1;
  background-image: url(${(props) => props.thumbnail});
  background-size: cover;
  background-position: center;
  height: 100px;
  position: relative;
  border-radius: 10px;
`;

const IconOverlay = Styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
`;

const Right = Styled.div`
  flex: 3;
  padding:10px;
  padding-top:0px;

`;

const TopDiv = Styled.div`
`;

const BottomDiv = Styled.div`
`;

const Title = Styled.span`
  color: ${(props) => props.theme.fontPri};
  font-size: 16px;
  font-weight: bold;
`;

const Views = Styled.span`
  color: ${(props) => props.theme.fontSec};
`;

const NoteCard = ({ title, subtitle, id, thumbnail }) => {
  const { colorTheme } = useContext(ContextProvider);
  const navigate = useNavigate();
  const IconStyle = {
    height: "30px",
    width: "30px",
    color: colorTheme.mainColor,
  };

  return (
    <Container theme={colorTheme} onClick={() => navigate(`/notes/${id}`)}>
      <Top theme={colorTheme}>
        <Left thumbnail={thumbnail}>
          <IconOverlay>
            <InsertDriveFileRoundedIcon style={IconStyle} />
          </IconOverlay>
        </Left>
        <Right>
          <TopDiv>
            <Title theme={colorTheme}>
              {title.length > 60 ? `${title.substring(0, 40)}...` : title}
            </Title>
          </TopDiv>
          <BottomDiv>
            <Views theme={colorTheme}>
              {subtitle.length > 40
                ? `${subtitle.substring(0, 60)}...`
                : subtitle}
            </Views>
          </BottomDiv>
        </Right>
      </Top>
    </Container>
  );
};

export default NoteCard;
