import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import OrderProductItem from "../General/OrderProductItem";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";
import PendingRoundedIcon from "@mui/icons-material/PendingRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import WatchLaterRoundedIcon from "@mui/icons-material/WatchLaterRounded";
import TimeAgo from "../../utils/TimeAgo";

const Product = Styled.div`
border-radius:10px;
margin-bottom:10px;
padding:7px 12px;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
background-color:${(props) => props.theme.backPri};
`;

const TopContainer = Styled.div`
width:100%;
flex:1;`;

const ProductContainer = Styled.div`
box-sizing:border-box;
width:100%;
flex:1;

`;

const BottomContainer = Styled.div`
width:100%;
flex:1;`;

const TableRowContainer = Styled.div`
padding:10px;
display:flex;
align-items:center;
justify-content:center;
box-sizing:border-box;
max-width:100%;`;

const IconContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
max-width:40px;
max-width:40px;`;

const ContentContainer = Styled.div`
flex:1;
width:100%;
margin-left:10px;
`;

const Span = Styled.span`
font-size:20px;
font-weight:bolder;`;

const OrderItem = ({ item, handleDelete = null }) => {
  const { colorTheme } = useContext(ContextProvider);
  const IconStyle = {
    height: "30px",
    width: "30px",
    color: colorTheme.fontPri,
  };

  return (
    <Product theme={colorTheme} key={item._id}>
      <TopContainer>
        <TableRowContainer>
          <IconContainer>
            <InfoRoundedIcon style={IconStyle} />
          </IconContainer>
          <ContentContainer>
            <Span>OrderId: {item.orderId}</Span>
          </ContentContainer>
        </TableRowContainer>
      </TopContainer>
      <ProductContainer>
        {item.orderItems.map((item) => (
          <OrderProductItem item={item} />
        ))}
      </ProductContainer>
      <BottomContainer>
        <TableRowContainer>
          <IconContainer>
            <CurrencyRupeeRoundedIcon style={IconStyle} />
          </IconContainer>
          <ContentContainer>
            <Span>Total {item.total}</Span>
          </ContentContainer>
        </TableRowContainer>
        <TableRowContainer>
          <IconContainer>
            {item.status === "pending" ? (
              <PendingRoundedIcon style={IconStyle} />
            ) : item.status === "complete" ? (
              <DownloadDoneRoundedIcon style={IconStyle} />
            ) : (
              <CloseRoundedIcon style={IconStyle} />
            )}
          </IconContainer>
          <ContentContainer>
            <Span>{item.status}</Span>
          </ContentContainer>
        </TableRowContainer>
        <TableRowContainer>
          <IconContainer>
            <WatchLaterRoundedIcon style={IconStyle} />
          </IconContainer>
          <ContentContainer>
            <Span>{TimeAgo(item.createdAt)}</Span>
          </ContentContainer>
        </TableRowContainer>
      </BottomContainer>
    </Product>
  );
};

export default OrderItem;
