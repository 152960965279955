import React from "react";
import styled, { keyframes } from "styled-components";

// Define keyframes for the loader spin animation
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Create a styled loader component
const LoaderSpan = styled.span`
  display: inline-block;
  width: ${(props) => props.size || "20px"};
  height: ${(props) => props.size || "20px"};
  border: 3px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-top: 3px solid; /* The colored border */
  border-top-color: white; /* The colored border */
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const Loader = ({ size }) => {
  return <LoaderSpan size={size} />;
};

export default Loader;
