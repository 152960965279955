import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";

const Container = Styled.div`
position:absolute;
right: 0;
bottom: 0;
top: calc(100vh - 25px);
height:25px;
overflow:hidden;
width:100vw;
display:flex;
align-items:center;
justify-content:center;
background-color:transparent;
z-index:6;
`;

const Wrapper = Styled.div`
width:100%;
background-color:${(props) =>
  props.type === "success"
    ? "#008000"
    : props.type === "warning"
    ? "#C1C302"
    : "#FF4C00"};
color:white;
height:25px;
display:flex;
align-items:center;
justify-content:center;
transition:all 0.2s ease-in-out;
`;

const ComponentWrapper = Styled.div`
flex:${(props) => props.width};
display:flex;
align-items:center;
justify-content:center;
`;

const Span = Styled.span`
font-size:14px;
`;

const Toast = (props) => {
  const { alert, theme, colorTheme } = useContext(ContextProvider);

  return (
    <Container theme={colorTheme}>
      <Wrapper
        theme={theme}
        type={alert.type}
        style={{ transform: alert.transform }}
        id="wrap"
      >
        <ComponentWrapper width="4">
          <Span>{alert.message}</Span>
        </ComponentWrapper>
      </Wrapper>
    </Container>
  );
};

export default Toast;
