import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
import { Link } from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import { Mobile } from "../../responsive";

const Product = Styled.div`
  flex: 1;
  border-radius: 20px;
  padding: 12px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.backPri};
  ${Mobile({ width: "100%" })};
`;

const ProductDetail = Styled.div`
  border-radius: 20px;
  flex: 3;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const ImgContainer = Styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = Styled.img`
  border-radius: 10px;
  width: 150px;
  ${Mobile({ width: "120px" })};
`;

const Detail = Styled.div`
  flex: 3;
  padding: 10px;
  padding-top: 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const DetailText = Styled.span`
  /* padding: 4px; */
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.fontPri};

`;
const DetailText2 = Styled.span`
  /* padding: 4px; */
  font-size: 17px;
  font-weight: bold;
  color: ${(props) => props.theme.fontSec};
`;

const Button = Styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
  background-color: ${(props) => props.theme.mainColor};
  border: none;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.2s ease;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
  }
`;
const HeadingContainer = Styled.div`
padding:5px;
`;
const SubHeadingContainer = Styled.div`
padding:5px;
`;
const BuyContainer = Styled.div`
  padding: 5px;
  flex: 1;
`;

const UnitItem = ({ thumbnail, unitTitle, subtitle, id }) => {
  const { colorTheme } = useContext(ContextProvider);
  const IconStyle = {
    height: "30px",
    width: "30px",
    paddingRight: "5px",
  };

  return (
    <Product theme={colorTheme} key={id}>
      <ProductDetail>
        <Link
          to={`/unit/${id}`}
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            width: "100%",
          }}
        >
          <ImgContainer>
            <Img src={thumbnail} />
          </ImgContainer>
          <Detail>
            <HeadingContainer>
              <DetailText theme={colorTheme}>{unitTitle}</DetailText>
            </HeadingContainer>
            <SubHeadingContainer>
              <DetailText2 theme={colorTheme}>{subtitle}</DetailText2>
            </SubHeadingContainer>
            <BuyContainer>
              <Button theme={colorTheme}>
                <ArrowForwardIosRoundedIcon style={IconStyle} />
              </Button>
            </BuyContainer>
          </Detail>
        </Link>
      </ProductDetail>
    </Product>
  );
};

export default UnitItem;
