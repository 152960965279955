import React, { useContext, useState, useEffect, useRef } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import RenderNote from "./RenderNote";
import { logout } from "../redux/userReducer";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader";
import axios from "axios";
import { Mobile } from "../responsive";

const Container = Styled.div`
  background-color:${(props) => props.theme.backSec};
  padding:20px;
  border-radius:10px;
  box-sizing:border-box;
  color:${(props) => props.theme.fontPri};
  height:calc(100vh - 40px);
  height:${(props) => props.isMobile && "50vh"};
  width:100%;
  ${Mobile({ borderRadius: "0px" })};
`;

const Wrapper = Styled.div`
  height:100%;
  position:relative;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  overflow:scroll;
  &::-webkit-scrollbar{
      display:none;
  }
`;

const Input = Styled.input`
  flex:1;
  width:100%;
  height:30px;
  font-size:15px;
  padding:3px 5px;
  background-color:${(props) => props.theme.backPri};
  color:${(props) => props.theme.fontPri};
  border:none;
  border-radius:10px;
  outline:none;
`;

const TopContainer = Styled.div`
  background-color:${(props) => props.theme.backSec};
  
  position:sticky;
  top:0px;
  z-index:10;
`;

const HeadContainer = Styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:stretch;
`;

const IconContainer = Styled.div`
  height:35px;
  width:35px;
`;

const InputContainer = Styled.div`
  width:100%;
  padding-left:20px;
  padding-right:20px;
  box-sizing:border-box;
`;

const ToolContainer = Styled.div`
  height:content-fit;
  width:100%;
  padding:10px 0px;
  box-sizing:border-box;
  display:flex;
  align-items:center;
  justify-content:space-around;
`;

const AppendContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;  
  height: 40px;
  background-color: ${(props) => props.theme.mainColor};
  cursor: pointer;
  flex-shrink: 0;  
`;

const Span = Styled.span`
  font-weight:bold;
`;

const NoteContainer = Styled.div`
  flex:1;
  width:100%;
  overflow:scroll;
  box-sizing:border-box;
  &::-webkit-scrollbar{
      display:none;
  };
`;

const NoteWrapper = Styled.div`
  position:relative;
  margin-bottom: 5px;
  &:hover .overlay {
    opacity: 1;
  }
`;

const Overlay = Styled.div`
  position: absolute;
  padding:5px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  border-radius:5px;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const IconWrapper = Styled.div`
  display: flex;
  gap: 10px;
`;

const Notes = ({ toggleNote, isMobile, noteId }) => {
  // Hooks and State
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const [selectedVar, setSelectedVar] = useState("");
  const [notes, setNotes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [noteIndexToUpdate, setNoteIndexToUpdate] = useState(null); // Index of the note being updated
  const [initiallyFetched, setInitiallyFetched] = useState([]);
  const user = useSelector((state) => state.user.currentUser);
  const [saved, setSaved] = useState(false);
  const [fetchNotes, setFetchNotes] = useState(false);
  const [savedNotes, setSavedNotes] = useState(false);
  const [state, setState] = useState(0);
  const IconStyle = {
    height: "35px",
    width: "35px",
    cursor: "pointer",
  };

  // Effect to monitor selection
  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection().toString();
      if (selection.length !== 0) setSelectedVar(selection);
    };

    document.addEventListener("selectionchange", handleSelectionChange);
    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, []);

  // Fetch Notes
  useEffect(() => {
    const fetchNotes = async () => {
      setFetchNotes(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/notes/getNotesByRelated/${noteId}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
        console.log(response.data);
        const result = response.data.contents.map((item) => ({
          content: item.id.content,
          type: item.id.type,
          _id: response.data._id,
        }));
        setInitiallyFetched(result);
        setNotes(result);
      } catch (error) {
        console.log(error.message);
      }
      setFetchNotes(false);
    };

    fetchNotes();
  }, [toggleNote, state]);

  // Save Notes
  const handleSave = async () => {
    if (!notes || notes.length === 0) return;

    setSavedNotes(true);
    try {
      if (!initiallyFetched || initiallyFetched.length === 0) {
        // Create new notes
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/notes/createNotes`,
          {
            contents: notes.map(({ content, type }) => ({ content, type })),
            relatedTo: noteId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
        setState((state) => state + 1);
      } else {
        // Update existing notes
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/v1/notes/updateNotesById/${notes[0]._id}`,
          { contents: notes.map(({ content, type }) => ({ content, type })) },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
      }
      setSaved(true);
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
      }
    }
    setSavedNotes(false);
    // handleSave();
  };

  // Add or Update Note
  const addOrUpdateNote = (type, content) => {
    if (!content || content.trim() === "") return;

    if (editMode && noteIndexToUpdate !== null) {
      // Update existing note
      setNotes((prevNotes) => {
        const updatedNotes = [...prevNotes];
        updatedNotes[noteIndexToUpdate] = {
          ...updatedNotes[noteIndexToUpdate],
          type,
          content,
        };
        return updatedNotes;
      });
      setEditMode(false);
      setNoteIndexToUpdate(null);
    } else {
      // Add new note
      setNotes((prevNotes) => [...prevNotes, { type, content }]);
    }

    setSelectedVar(""); // Clear textbox
    setSaved(false);
    divRef.current?.scrollTo(0, divRef.current.scrollHeight);
  };

  // Append to Last Note
  const appendToLastNote = (content) => {
    if (!content || content.trim() === "") return;
    setNotes((prevNotes) => {
      if (prevNotes.length === 0) return prevNotes;
      const updatedNotes = [...prevNotes];
      updatedNotes[updatedNotes.length - 1].content += ` ${content}`;
      return updatedNotes;
    });
    setSaved(false);
    setSelectedVar("");
    divRef.current?.scrollTo(0, divRef.current.scrollHeight);
  };

  // Delete Note
  const deleteNote = (index) => {
    setNotes((prevNotes) => prevNotes.filter((_, i) => i !== index));
    setSaved(false);
  };

  // Handle Note Click
  const handleNoteClick = (index) => {
    const note = notes[index];
    setEditMode(true);
    setNoteIndexToUpdate(index);
    setSelectedVar(note.content); // Populate the textbox with existing content
  };

  return (
    <Container isMobile={isMobile} theme={colorTheme}>
      <Wrapper ref={divRef}>
        <TopContainer theme={colorTheme}>
          <HeadContainer>
            <IconContainer theme={colorTheme}>
              {saved ? (
                <DownloadDoneRoundedIcon
                  style={IconStyle}
                  onClick={handleSave}
                />
              ) : savedNotes ? (
                <Loader />
              ) : (
                <SaveRoundedIcon style={IconStyle} onClick={handleSave} />
              )}
            </IconContainer>
            <InputContainer>
              <Input
                theme={colorTheme}
                value={selectedVar}
                onChange={(e) => setSelectedVar(e.target.value)}
              />
            </InputContainer>
            <IconContainer theme={colorTheme}>
              <CloseRoundedIcon style={IconStyle} onClick={toggleNote} />
            </IconContainer>
          </HeadContainer>
          <ToolContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => appendToLastNote(selectedVar)}
            >
              <Span>A</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => addOrUpdateNote("heading1", selectedVar)}
            >
              <Span>H1</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => addOrUpdateNote("heading2", selectedVar)}
            >
              <Span>H2</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => addOrUpdateNote("heading3", selectedVar)}
            >
              <Span>H3</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => addOrUpdateNote("paragraph", selectedVar)}
            >
              <Span>P</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => addOrUpdateNote("list", selectedVar)}
            >
              <Span>L</Span>
            </AppendContainer>
          </ToolContainer>
        </TopContainer>
        <NoteContainer>
          {fetchNotes ? (
            <Loader />
          ) : (
            notes.map((note, index) => (
              <NoteWrapper key={index}>
                <div onClick={() => handleNoteClick(index)}>
                  <RenderNote blogData={{ contents: [note] }} />
                </div>
                <Overlay className="overlay">
                  <IconWrapper>
                    <EditIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNoteClick(index)}
                    />
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteNote(index)}
                    />
                  </IconWrapper>
                </Overlay>
              </NoteWrapper>
            ))
          )}
        </NoteContainer>
      </Wrapper>
    </Container>
  );
};

export default Notes;
