import React, { useContext, useEffect, useState, useRef } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../redux/loadReducer";
import { logout } from "../redux/userReducer";
import { Mobile, Phone } from "../responsive";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import html2canvas from "html2canvas";
import RenderNoteIndiv from "../components/RenderNoteIndiv"; // Assuming you have this component for rendering the note.
import Buffering from "../components/General/Buffering";
import NoItems from "../components/General/NoItems";
import axios from "axios";
const Container = Styled.div`
  padding-left:20px;
  padding-top:5px;
  padding-right:20px;
  color: ${(item) => item.theme.fontPri};
  ${Mobile({ paddingLeft: "7px", paddingTop: "7px", paddingRight: "7px" })};
`;

const Wrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items:flex-start;
  ${Phone({ flexDirection: "column" })};
`;

const Left = Styled.div`
  flex: 1;
  display:flex;
  align-items:center;
  width:100%;
  position: relative;
  ${Mobile({ justifyContent: "center", width: "100%" })};
`;

const IconButton = Styled.div`
  position: absolute;
  top: 10px;
  right: ${(props) => (props.type === "delete" ? "10px" : "70px")};
  width: 40px;
  height: 40px;
  background-color: ${(props) => props.theme.backPri};
  padding:5px;
  
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const Watermark = Styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
`;

const Note = () => {
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const loader = useSelector((state) => state.load.toggle);
  const [docData, setDocData] = useState(null);
  const location = useLocation();
  const noteId = location.pathname.split("/")[2]; // Extracting note ID from the URL
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const captureRef = useRef(null);
  const IconStyle = {
    height: "40px",
    width: "40px",
    color: colorTheme.fontPri,
  };
  useEffect(() => {
    const fetchNoteById = async () => {
      dispatch(setLoading(true));

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/notes/getNotesById/${noteId}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`, // Attach bearer token
            },
          }
        );
        let NoteData = response.data.contents.map((item) => {
          return {
            type: item.id.type,
            content: item.id.content,
          };
        });

        setDocData({
          NoteData,
          docTitle: response.data.title,
          subtitle: response.data.subtitle,
        });
      } catch (error) {
        if (error?.response?.status === 411) {
          setNotification("failure", "Sessions Exceeded! Please login again");
          dispatch(logout());
          navigate("/login");
          return;
        }
        setNotification("failure", error?.response?.data?.message);
      }
      dispatch(setLoading(false));
    };

    fetchNoteById();
  }, [noteId]);

  const handleDownload = async () => {
    if (captureRef.current) {
      try {
        const canvas = await html2canvas(captureRef.current);
        const dataURL = canvas.toDataURL("image/png");

        // Create a temporary link to download the image
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "note-content.png";
        link.click();
      } catch (error) {
        console.error("Failed to capture the content:", error);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/notes/deleteNoteById/${noteId}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      setNotification("success", "Note deleted successfully.");
      navigate("/mynotes");
    } catch (error) {
      setNotification(
        "failure",
        error?.response?.data?.message || "Failed to delete note."
      );
    }
  };

  return (
    <Container theme={colorTheme}>
      {loader === false ? (
        docData ? (
          <Wrapper theme={colorTheme}>
            <Left theme={colorTheme} ref={captureRef}>
              <RenderNoteIndiv blogData={{ contents: docData }} />
              {/* <Watermark></Watermark> */}
              <IconButton
                theme={colorTheme}
                type="delete"
                onClick={handleDelete}
              >
                <DeleteRoundedIcon style={IconStyle} />
              </IconButton>
            </Left>
          </Wrapper>
        ) : (
          <NoItems />
        )
      ) : (
        <Buffering />
      )}
    </Container>
  );
};

export default Note;
