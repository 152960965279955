import React, { useContext } from "react";
import { ContextProvider } from "../../App";

const Icon = () => {
  const { theme } = useContext(ContextProvider);
  //   const
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      transform="rotate(30)"
      width="40px"
      height="40px"
      style={{ width: "40px", height: "40px" }}
    >
      <g transform="rotate(0, 12, 12)">
        <circle
          cx="6"
          cy="17"
          r="3.5"
          fill={theme === "dark" ? "white" : "black"}
        />
        <circle cx="12" cy="7" r="3.5" fill="#387ADF" />
        <circle cx="18" cy="17" r="3.5" fill="#387ADF" />
      </g>
    </svg>
  );
};

export default Icon;
