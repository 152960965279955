import React, { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import { useSelector } from "react-redux";
import { Mobile } from "../responsive";
import { ContextProvider } from "../App";
import { useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import Notes from "../components/Notes";
import Axios from "axios";
import ReactMarkdown from "react-markdown";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import Buffering from "../components/General/Buffering";
import remarkGfm from "remark-gfm";
import LoginPrompt from "../components/General/LoginPrompt";
import remarkMath from "remark-math";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import rehypeKatex from "rehype-katex";
import { useDispatch } from "react-redux";
import { logout } from "../redux/userReducer";
import Noteshare from "../components/General/Icon";

import "katex/dist/katex.min.css";
const ParentContainer = Styled.div`
  height: 100vh;
  width: 100vw;
  visibility: ${(props) => (props.toggle ? "visible" : "hidden")};
  background-color:${(props) => props.theme.backSec};
  backdrop-filter: blur(20px);
  overflow:hidden;
  display: flex;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content:center;
`;
const IconContainer = Styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
`;
const Left = Styled.div`
  min-width:60%;
  max-width:60%;
  flex: ${(props) => (props.isViewNoteActive ? "0.6" : "1")};
  height: 100vh;
  color: ${(props) => props.theme.fontPri};
  font-family: "Noto Sans", serif;
  /* font-weight: <weight>; */
  background-color: ${(props) => props.theme.backSec};
  overflow-y: scroll;
  box-sizing:border-box;
  padding:20px;
  ${Mobile({
    height: (props) => (props.isViewNoteActive ? "calc(50vh)" : "calc(100vh)"),
    padding: "10px",
    minWidth: "100%",
  })};

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.fontPri};
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.fontSec};
    border-radius: 10px;
  }



.markdown-container {
  font-size: 16px; 
  line-height: 1.5; 
}

h1 {
  font-size: 36px;
  margin:32px 0px;
  margin-bottom:29px;


}

h2 {
  font-size: 32px;
  margin:30px 0px;
  margin-bottom:27px;
  
  
}

h3 {
  font-size: 31px;
  margin:27px 0px;
  margin-bottom:24px;
  
  
}

h4 {
  font-size: 24px;
  margin:20px 0px;
  margin-bottom:17px;
  
}

h5 {
  font-size: 20px;
  margin:20px 0px;
  margin-bottom:17px;
  
}

h6 {
  font-size: 18px;
  margin:20px 0px;
  margin-bottom:17px;
  
}

p {
  font-size: 24px;
  margin:10px 0px;
  line-height:1.3;
}

blockquote {
  font-size: 24px;

  border-left: 4px solid #ccc;
  padding-left: 12px;
}

code {
  font-size: 20px;
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 4px;
};
pre {
  font-size: 20px;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 6px;
  overflow-x: auto;
}

ul,ol {
  font-size: 20px;
  margin-left: 0px;
}

li {
  font-size: 24px;
  line-height:1.3;
  list-style-type:disc;
  
}

a {
  font-size: 22px;
  color: #007bff;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}
em {
  font-style: normal;
}
/* strong {
  font-size: 24px;
  font-weight: bold;
} */


del {
  font-size: 22px;
  text-decoration: line-through;
}

u {
  
  text-decoration: underline;
};

table {
  width: 100%;
  border-collapse: collapse;
  background-color: ${({ theme }) => theme.backSec};
  color: ${({ theme }) => theme.fontPri};
  border-radius: 8px;
  overflow: hidden; /* Ensures rounded corners apply to table content */

  th, td {
    border: 1px solid ${({ theme }) => theme.backSec};
    padding: 12px;
    text-align: left;
    font-size: 14px;
  }

  th {
    background-color: ${({ theme }) => theme.backSec};
    color: ${({ theme }) => theme.fontPri};
    font-weight: bold;
  }
}
`;
const Header = Styled.div`
display:flex;
align-items:center;
justify-content:space-between;`;
const Right = Styled.div`
  ${(props) =>
    props.isViewNoteActive === false
      ? "transform: translateX(+100%);"
      : "margin: auto;"}
  width:40%;
  color: ${(props) => props.theme.fontPri};
  background-color: ${(props) => props.theme.backSec};
  display: ${(props) => (props.isViewNoteActive ? "block" : "none")};
  ${Mobile({ display: "none" })};
`;

const FloatingButton = Styled.button`
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
  background-color: ${(props) => props.theme.mainColor};
  border: none;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.2s ease;
  /* &:hover {
    transform: scale(1.1);
  } */
  &:active {
    transform: scale(0.95);
  }
`;

const NoteButton = Styled(FloatingButton)`
display:flex;
align-items:center;
justify-content:center;
  bottom: 80px;
  right: 20px;
`;
const DownloadButton = Styled(FloatingButton)`
display:flex;
align-items:center;
justify-content:center;
  bottom: 20px;
  right: 20px;
`;

const NotesRenderMobile = Styled.div`
display: ${(props) => (props.isViewNoteActive ? "flex" : "none")};
display:none;
position:absolute;
height:50vh;
width:100%; 
bottom:0px;
left:0px;
right:0px;
box-sizing:border-box;
${Mobile({ display: "flex" })};
`;
const NoteContainer = Styled.div`
width:100%;`;

const TitleContainer = Styled.div`
  padding: 0px 10px 0px 10px;
 
`;

const Title = Styled.span`
  font-weight: 600;
  font-size: 30px;
  margin: 0px auto;
  
  cursor: pointer;
  color: ${(props) => props.theme.fontPri};
  font-family: "Nunito", sans-serif;
  ${Mobile({ fontSize: "28px" })};
  `;

const Span = Styled.span`
  font-size: 30px;
  
  color: #387ADF;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
`;

const ReadDoc = () => {
  const { colorTheme, theme, ToggleTheme, setNotification } =
    useContext(ContextProvider);
  const user = useSelector((state) => state.user.currentUser);
  const [docData, setDocData] = useState({});
  const [viewNote, setViewNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const UnitId = location.pathname.split("/")[2];
  const [error, setError] = useState(false);
  // const downloadPdf = () => {
  //   const container = document.querySelector(".markdown-container");

  //   if (!container) {
  //     setNotification("failure", "No content found to download");
  //     return;
  //   }
  //   const liElements = container.querySelectorAll("li");
  //   liElements.forEach((li) => {
  //     li.style.listStyleType = "disc"; // Apply numbered list style
  //   });
  //   const pdf = new jsPDF("p", "mm", "a4"); // Set PDF to A4 size
  //   // alert("hello world");

  //   // Scale the container content to fit within the PDF page
  //   pdf.html(container, {
  //     callback: (doc) => {
  //       // Embed the image watermark
  //       const watermarkImage = new Image();
  //       watermarkImage.src = "/static/docshare-logo-removebg-preview.png"; // Path to your watermark image

  //       watermarkImage.onload = () => {
  //         const pageCount = doc.internal.pages.length;

  //         for (let i = 1; i <= pageCount; i++) {
  //           doc.setPage(i);
  //           const { width, height } = doc.internal.pageSize;

  //           const x = width - 47;
  //           const y = 7;
  //           const watermarkWidth = 40;
  //           const watermarkHeight = 10;

  //           // Add the watermark image
  //           doc.addImage(
  //             watermarkImage,
  //             "PNG",
  //             x,
  //             y,
  //             watermarkWidth,
  //             watermarkHeight,
  //             undefined,
  //             "FAST"
  //           );

  //           // Add a clickable link over the watermark
  //           const linkUrl = "https://docshare.in/home"; // URL you want the watermark to redirect to
  //           doc.link(x, y, watermarkWidth, watermarkHeight, { url: linkUrl });
  //           pdf.save("document.pdf");
  //         }
  //       };
  //     },
  //     x: 20,
  //     y: 20,
  //     width: 170, // Adjust width to fit A4 dimensions (210mm width minus margins)

  //     windowWidth: container.scrollWidth, // Match the container's width
  //     pageBreak: "text",
  //   });
  // };
  const IconStyle = {
    height: "35px",
    width: "35px",
    cursor: "pointer",
    color: colorTheme.fontPri,
  };
  const handleToggleNote = () => {
    if (!user) {
      setNotification("failure", "Please login to continue");
      return;
    }
    setViewNote((prev) => !prev);
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await Axios.get(
          user
            ? `${process.env.REACT_APP_API_URL}/api/v1/unit/getUnitByIdLoggedIn/${UnitId}`
            : `${process.env.REACT_APP_API_URL}/api/v1/unit/getUnitByIdUnLogged/${UnitId}`,
          { headers: { Authorization: `Bearer ${user?.access_token}` } }
        );
        setDocData(res.data);
      } catch (error) {
        dispatch(logout());

        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, [UnitId]);

  return (
    <ParentContainer theme={colorTheme} toggle={true}>
     
      <NoteButton theme={colorTheme} onClick={handleToggleNote}>
        <DescriptionRoundedIcon />
      </NoteButton>
      <DownloadButton theme={colorTheme} onClick={ToggleTheme}>
        {theme === "light" ? <DarkModeIcon /> : <LightModeIcon />}
      </DownloadButton>

      <Left isViewNoteActive={viewNote} theme={colorTheme}>
        {loading ? (
          <Buffering />
        ) : (
          <>
            <Header>
              <IconContainer onClick={() => navigate("/home")}>
                <Noteshare style={IconStyle} />
              </IconContainer>
              <TitleContainer onClick={() => navigate("/home")}>
                <Title theme={colorTheme}>
                  Doc
                  <Span>Share</Span>
                </Title>
              </TitleContainer>
            </Header>
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
              className={"markdown-container"}
              components={{
                div: ({ node, ...props }) =>
                  node.tagName === "math" ? (
                    <div {...props} />
                  ) : (
                    <div {...props} />
                  ),
              }}
            >
              {docData.markdown}
            </ReactMarkdown>
          </>
        )}
        {docData.cropped === true && (
          <LoginPrompt message={"Login to read full document"} />
        )}
        {error && (
          <LoginPrompt message={"Sessions Exceeded! Please Login Again."} />
        )}
      </Left>

      <Right isViewNoteActive={viewNote} theme={colorTheme}>
        <Notes toggleNote={handleToggleNote} noteId={UnitId} />
      </Right>

      {viewNote && (
        <NotesRenderMobile>
          <NoteContainer>
            <Notes
              isMobile={true}
              toggleNote={handleToggleNote}
              noteId={UnitId}
            />
          </NoteContainer>
        </NotesRenderMobile>
      )}
    </ParentContainer>
  );
};

export default ReadDoc;
