import { createSlice } from "@reduxjs/toolkit";

const campaignSlice = createSlice({
  name: "campaign",
  initialState: {
    currentCampaign: "default",
  },
  reducers: {
    setCampaign: (state,action) => {
        console.log(action);
      state.currentCampaign =action.payload;
    },
  },
});

export const { setCampaign } = campaignSlice.actions;

export default campaignSlice.reducer;
