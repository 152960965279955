import React, { useState, useContext } from "react";
import Icon from "../components/General/Icon";
import Text from "../components/General/Text";
import Styled from "styled-components";
import Axios from "axios";
import { ContextProvider } from "../App";
import { Phone, Mobile } from "../responsive";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../redux/userReducer";
import Button from "../components/General/Button";
import InputContainer from "../components/General/InputContainer";
import InputContainerWithEye from "../components/General/InputContainerWithEye";

const Container = Styled.div`
height:100%;
display:flex;
align-items:center;
justify-content:center;
background-color:${(props) => props.bgColor};
box-sizing:border-box;
overflow:scroll;
&::-webkit-scrollbar{
  display:none;
};
`;

const Wrapper = Styled.div`
width:30%;
height:content-fit;
padding-bottom:20px;
box-sizing:border-box;
border-radius:20px;
background-color:${(props) => props.bgColor};
${Mobile({
  height: "contentFit",
  width: "60vw",
})};
${Phone({
  height: "contentFit",
  width: "90vw",
})};
${(props) =>
  props.disabled &&
  `opacity: 0.3;
  pointer-events: none;
  filter: blur(1px);
`}
`;

const HeadingContainer = Styled.div`
padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContentContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
`;

const FormContainer = Styled.div`
flex:1;
width:100%;
`;

const Form = Styled.form`
padding:5px 20px;
`;

const AutoLoginContainer = Styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:center;
padding:5px 20px;
`;

const PartitionContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;

`;

const ButtonContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 10px;
`;

const TextLink = Styled(Link)`
  text-decoration: none;
  color: ${(props) => props.color || "blue"};
  font-size: 14px;
  margin-top: 10px;
`;

const PasswordContainer = Styled.div`
  position: relative;
`;
const IconContainer = Styled.div`
padding:5px;
cursor:pointer;`;

const TextContainer = Styled.div``;
const AgreementContainer = Styled.div`
padding:10px 17px;
text-align:center;
color:${(props) => props.theme.fontPri};`;

const Agreement = Styled.span``;

const Register = () => {
  const { colorTheme, theme, setNotification } = useContext(ContextProvider);
  const { backPri, backSec, fontPri, fontSec } = colorTheme;
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const referrerId = searchParams.get("referrerId");
  const redirectTo = searchParams.get("redirectTo");
  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };
  const handleTogglePassword2 = () => {
    setPasswordVisible2(!passwordVisible2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setNotification("warning", "Please enter your email");
      return;
    }
    if (!password) {
      setNotification("warning", "Please enter your password");
      return;
    }
    if (!repeatPassword) {
      setNotification("warning", "Please confirm your password");
      return;
    }
    if (password !== repeatPassword) {
      setNotification("warning", "Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      await Axios.post(
        referrerId
          ? `${process.env.REACT_APP_API_URL}/api/v1/auth/register?referrerId=${referrerId}`
          : `${process.env.REACT_APP_API_URL}/api/v1/auth/register`,
        {
          email: email,
          password: password,
          repeat_password: repeatPassword,
        }
      );

      setNotification("success", "Registered Successfully");
      navigate(
        redirectTo
          ? `/login?redirectTo=${encodeURIComponent(redirectTo)}`
          : "/login"
      );
    } catch (error) {
      setNotification(
        "failure",
        error.response?.data?.message || "Registration failed"
      );
    }
    setLoading(false);
  };

  //elements
  const handleOauthRegister = async (credentialResponse) => {
    setLoading(true);
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/google-oauth-login`,
        {
          token: credentialResponse.credential,
        }
      );
      setNotification("success", "Logged-In Successfully");
      dispatch(loginSuccess(data));
      navigate(redirectTo || "/home");
    } catch (error) {
      setNotification(
        "failure",
        error.response?.data?.message || "Login failed"
      );
    }
    setLoading(false);
  };

  return (
    <Container bgColor={backPri}>
     
      <Wrapper disabled={loading} bgColor={backSec}>
        <HeadingContainer>
          <IconContainer onClick={() => navigate("/home")}>
            <Icon />
          </IconContainer>
          <TextContainer>
            <Text
              ftColor={fontPri}
              val={"Register"}
              ftSize={"30px"}
              ftWeight={"Bolder"}
            ></Text>
          </TextContainer>
        </HeadingContainer>
        <ContentContainer>
          <AutoLoginContainer>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <GoogleLogin
                theme={theme === "dark" ? "filled_blue" : "outline"}
                shape={"pill"}
                onSuccess={(credentialResponse) =>
                  handleOauthRegister(credentialResponse)
                }
                cancel_on_tap_outside={true}
                onError={() => {
                  console.log("Registration Failed");
                }}
              />
            </GoogleOAuthProvider>
          </AutoLoginContainer>
          <PartitionContainer>
            <Text
              ftColor={fontSec}
              val={"or"}
              ftSize={"20px"}
              ftWeight={"bolder"}
            ></Text>
          </PartitionContainer>
          <FormContainer>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <InputContainer
                label="email"
                placeholder="Email"
                type="email"
                name="email"
                value={email}
                setInput={setEmail}
              />
              <PasswordContainer>
                <InputContainerWithEye
                  label="password"
                  placeholder="Password"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  setInput={setPassword}
                  handleTogglePassword={handleTogglePassword}
                  passwordVisible={passwordVisible}
                />
              </PasswordContainer>
              <PasswordContainer>
                <InputContainerWithEye
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type={passwordVisible2 ? "text" : "password"}
                  name="confirmpassword"
                  setInput={setRepeatPassword}
                  handleTogglePassword={handleTogglePassword2}
                  passwordVisible={passwordVisible2}
                />
              </PasswordContainer>
              <ButtonContainer>
                <Button
                  isDisabled={loading}
                  text={"Register"}
                  type="submit"
                ></Button>
              </ButtonContainer>
            </Form>
          </FormContainer>

          <TextLink
            to={
              redirectTo
                ? `/login?redirectTo=${encodeURIComponent(redirectTo)}`
                : "/login"
            }
            color={fontPri}
          >
            Already Have an Account? Login
          </TextLink>
          <AgreementContainer theme={colorTheme}>
            <Agreement>
              By Signing-Up, I agree to{" "}
              <TextLink color={fontPri} to="/privacy-policy">
                {" "}
                Privacy Policy
              </TextLink>{" "}
              and{" "}
              <TextLink color={fontPri} to="/terms-conditions">
                Terms and Conditions
              </TextLink>
            </Agreement>
          </AgreementContainer>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Register;
